<template>
  <div class="content">
    <section class="listing-property">
      <div class="mobile-search">
        <div class="container-fluid plr-80">
          <b-form-input
            v-model="location.destination"
            placeholder="Search Location"
            class="search-icon"
            autocomplete="off"
            id="search-form-mobile"
            @keypress.enter="findPlaces"
            @click="$refs.typesenseSearchMobile.show()"
          ></b-form-input>
          <TypesenseSearch
            ref="typesenseSearchMobile"
            @select="location = $event"
            top="50px"
          />
        </div>
        <div class="container-fluid plr-80">
          <div>
            <b-form-input
              :placeholder="$t('listing.checkIn')"
              class="input check-out my-2"
              id="from-mobile"
              readonly
              v-model="date.checkIn"
            ></b-form-input>
            <b-form-input
              :placeholder="$t('listing.checkOut')"
              class="input my-2"
              v-model="date.checkOut"
              id="to-mobile"
              readonly
            ></b-form-input>
          </div>
          <PrimaryButton
            class="mt-3"
            @click="findPlaces"
            full
            :text="$t('listing.search')"
          />
          <div v-if="destination" class="my-4 pb-2 font-weight-bold text-main">
            <p v-if="fetching">
              {{ destination }}: {{ $t("listing.findingDeals") }}
            </p>
            <p v-else>
              {{ destination }}: {{ properties.totalElements }}
              {{ $t("listing.dealsFound") }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-md-4 col-lg-3 listing-left-sec">
            <div class="search-wrapper custom-calender">
              <h2>{{ $t("listing.search") }}</h2>
              <div class="form-outer">
                <b-row class="mb-2">
                  <b-col md="12">
                    <label for="input-small">{{
                      $t("listing.destination")
                    }}</label>
                  </b-col>
                  <b-col md="12">
                    <div class="relative">
                      <b-form-input
                        id="search-form"
                        autocomplete="off"
                        :placeholder="$t('listing.searchLocation')"
                        class="input check-in"
                        :value="location.destination"
                        readonly
                        @click="$refs.typesenseSearchDesktop.show()"
                      ></b-form-input>
                      <TypesenseSearch
                        ref="typesenseSearchDesktop"
                        @select="location = $event"
                        top="50px"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12 mb-2">
                    <label for="input-small">{{ $t("listing.checkIn") }}</label>
                    <b-form-input
                      :placeholder="$t('listing.checkIn')"
                      class="input check-out"
                      id="from-desktop"
                      readonly
                      v-model="date.checkIn"
                    ></b-form-input>
                  </b-col>
                  <b-col md="12 mb-2">
                    <label for="input-small">{{
                      $t("listing.checkOut")
                    }}</label>
                    <b-form-input
                      :placeholder="$t('listing.checkOut')"
                      class="input"
                      id="to-desktop"
                      v-model="date.checkOut"
                      readonly
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="submit-wrapper mt-2">
                  <b-col md="12">
                    <b-button class="" @click="findPlaces">{{
                      $t("listing.search")
                    }}</b-button>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="filter-wrapper" v-if="!filtersLoaded">
              <div class="text-center">{{ $t("listingFilter.loading") }}</div>
            </div>
            <div class="filter-wrapper" v-else>
              <!-- <div class="budget col-12">
                <h4>{{ $t("listingFilter.facilities") }}</h4>
                <b-row
                  v-for="facility in facilities"
                  :key="facility.name"
                  class=""
                >
                  <b-col md="8" class="checkbox">
                    <b-form-checkbox
                      v-model="selectedFacilities"
                      :value="facility.id"
                      size="lg"
                      @change="applyFilters"
                      >{{ facility.name }}</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="2" class="text-right checktext">
                    {{ facility.count }}
                  </b-col>
                </b-row>
              </div> -->
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.budget") }}</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 0 - € 50</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 50 - € 100</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 100 - € 150</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 150 - € 200</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">€ 200+</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.typeOfPlace") }}</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Entire place</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hostel</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Private room</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.popularFilter") }}</h4>
                <b-row class="">
                  <b-col md="8" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Breakfast included</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="4" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Hotels</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Very good: 8+</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 3 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Double bed</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="8" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Free cancellation</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="4" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="8" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Private bathroom</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="4" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="8" class="checkbox">
                    <b-form-checkbox size="lg">Apartments</b-form-checkbox>
                  </b-col>
                  <b-col md="4" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.healthAndSafety") }}</h4>
                <b-row class="">
                  <b-col md="10" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Properties with additional health & safety
                      measures</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="2" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.starRating") }}</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">1 star</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">2 stars</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">3 stars</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">4 stars</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">5 stars</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Unrated</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.distanceFromCenter") }}</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 1 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 3 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Less that 5 km</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
              <div class="budget col-12">
                <h4>{{ $t("listingFilter.landmarks") }}</h4>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Alexanderplatx</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">ALEXA</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg"
                      >Brandenburg Gate</b-form-checkbox
                    >
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="6" class="checkbox">
                    <b-form-checkbox size="lg">Reichstag</b-form-checkbox>
                  </b-col>
                  <b-col md="6" class="text-right checktext"> 31 </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 listing-right-sec">
            <div class="row align-items-center top-bar">
              <div class="col-md-6">
                <div v-if="destination">
                  <h2 v-if="fetching">
                    {{ destination }}: {{ $t("listing.findingDeals") }}
                  </h2>
                  <h2 v-else>
                    {{ destination }}: {{ properties.totalElements }}
                    {{ $t("listing.dealsFound") }}
                  </h2>
                </div>
                <div v-else>
                  <h2>{{ $t("listing.enterDestination") }}</h2>
                </div>
              </div>
              <div
                class="col-md-6 text-right"
                v-if="
                  this.savedLocation.latitude && this.savedLocation.longitude
                "
              >
                <p class="position-relative float-right">
                  <img src="../assets/images/map.png" alt="" />
                  <a
                    :href="`/listing_map?q=${this.location.latitude},${this.location.longitude},${this.location.zoom}`"
                    target="_blank"
                    class="show-on-map"
                    >{{ $t("listing.showOnMap") }}</a
                  >
                </p>
              </div>
            </div>

            <div style="min-height: 40vh">
              <div
                v-for="property of propertiesList"
                :key="property.id"
                class="row hotel-block"
              >
                <div class="col-md-4 col-lg-5">
                  <div class="hotel-slider">
                    <b-carousel
                      id="carousel-fade"
                      :interval="3000"
                      style="text-shadow: 0px 0px 2px #000"
                      fade
                      indicators
                      img-width="1024"
                      img-height="480"
                      ><div @click="openProperty(property.id)">
                        <template v-if="property.media.length">
                          <div v-for="item of property.media" :key="item.id">
                            <b-carousel-slide
                              :img-src="item.url"
                            ></b-carousel-slide>
                          </div>
                        </template>
                        <template v-else>
                          <b-carousel-slide
                            v-for="i of 2"
                            :key="i"
                            img-src="/images/property-default-image.jpg"
                          ></b-carousel-slide>
                        </template>
                      </div>
                    </b-carousel>
                  </div>
                </div>
                <div class="col-md-8 col-lg-7 hotelTxtInner">
                  <div class="hotel-txt">
                    <div class="hotel-rating row">
                      <div class="col-md-8 px-3 mt-md-0 mt-3">
                        <p class="d-md-block d-none">{{ property.name }}</p>
                        <h4 class="d-flex align-items-center">
                          <span
                            ><div
                              @click="openProperty(property.id)"
                              style="color: #444; cursor: pointer"
                            >
                              {{ property.name }}
                            </div></span
                          >
                          <StarRating
                            v-if="property.ratingValue > 0"
                            :value="ratingValue(property.ratingValue)"
                          />
                        </h4>
                        <div class="access">
                          <li v-if="property.addresses">
                            <a
                              :href="`https://www.google.com/maps?q=${property.geoCode.latitude},${property.geoCode.longitude}`"
                              target="_blank"
                              >{{ property.addresses[0] }}</a
                            >
                          </li>
                          <li>
                            <a
                              :href="`/listing_map?q=${property.geoCode.latitude},${property.geoCode.longitude},17`"
                              target="_blank"
                              >{{ $t("listing.showOnMap") }}</a
                            >
                          </li>
                          <li>
                            {{
                              $t("property.distanceFromCenter", {
                                distance: getDistanceInKm(
                                  property.geoCode.latitude,
                                  property.geoCode.longitude
                                ),
                              })
                            }}
                          </li>
                        </div>
                      </div>
                      <div
                        class="col-md-4 d-flex align-items-center justify-content-md-end review"
                      >
                        <div class="mr-3">
                          <h5 class="text-center">
                            {{
                              getTranslatedRating(
                                parseFloat(property.ratingValue) * 2
                              )
                            }}
                          </h5>
                          <div v-if="property.ratingCount > 0" class="small">
                            {{ property.ratingCount }}
                            {{
                              $tc("property.reviewCount", property.ratingCount)
                            }}
                          </div>
                        </div>
                        <span class="rating">{{
                          ratingValue(parseFloat(property.ratingValue) * 2)
                        }}</span>
                      </div>
                      <div class="col-12 small mt-2">
                        {{ propertyDescription(property.descriptions) }}
                      </div>
                    </div>
                    <div
                      class="availability justify-content-between d-flex align-items-center mt-3"
                    >
                      <div>
                        <b-badge
                          v-if="property.distributorType === 'A'"
                          variant="light"
                          class="text-md px-3 py-2"
                          >{{ $t("listing.instantBookingRealTime") }}</b-badge
                        >
                        <b-badge
                          v-else-if="property.distributorType === 'B'"
                          variant="light"
                          class="text-md px-3 py-2"
                          >{{ $t("listing.instantBooking") }}</b-badge
                        >
                        <b-badge
                          v-else-if="property.distributorType === 'C'"
                          variant="light"
                          class="text-md px-3 py-2"
                          >{{ $t("listing.bookingOnRequest") }}</b-badge
                        >
                      </div>
                      <a
                        :href="propertyUrl(property.id)"
                        class="btn text-white btn-main"
                        >{{ $t("listing.viewDetails") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <InfiniteLoading
                ref="infiniteLoading"
                @infinite="loadMore"
                :identifier="infiniteId"
                v-if="location.latitude && location.longitude"
              >
                <div slot="spinner"><Spinner small /></div>
                <div slot="no-results" class="my-5">
                  {{ $t("listing.noPropertiesFound") }}
                </div>
              </InfiniteLoading>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import Spinner from "./Widgets/Spinner.vue";
import StarRating from "./Widgets/StarRating.vue";
import InfiniteLoading from "vue-infinite-loading";
import DateRangePicker from "../helpers/datepicker/index";
import TypesenseSearch from "./Widgets/TypesenseSearch.vue";

export default {
  name: "PropertyListing",
  components: {
    Spinner,
    StarRating,
    InfiniteLoading,
    TypesenseSearch,
  },

  data() {
    return {
      propertiesList: [],
      location: {
        destination: "",
        latitude: 0,
        longitude: 0,
      },
      savedLocation: {
        destination: "",
        latitude: 0,
        longitude: 0,
      },
      date: {
        checkIn: null,
        checkOut: null,
      },
      destination: "",
      currentPage: 1,
      perPage: 10,
      selectedAdults: 1,
      isLoading: false,
      noMoreData: false,
      infiniteId: +new Date(),
      filtersLoaded: false,
      facilities: [],
      selectedFacilities: [],
    };
  },
  async mounted() {
    if (window.innerWidth > 767) {
      new DateRangePicker("#from-desktop", "#to-desktop", {
        smartCalendar: false,
      });
    } else {
      new DateRangePicker("#from-mobile", "#to-mobile", {
        smartCalendar: false,
      });
    }
    this.facilities = await this.getAllFacilities();
    this.filtersLoaded = true;
  },
  created() {
    const { latitude, longitude, destination } = this.$route.query;
    if (latitude && longitude) {
      this.location.latitude = latitude;
      this.location.longitude = longitude;

      this.savedLocation.latitude = latitude;
      this.savedLocation.longitude = longitude;
      this.destination = destination;
    }
  },
  computed: {
    ...mapState("property", ["properties", "fetching"]),
  },
  methods: {
    ...mapActions("property", ["searchProperties", "getAllFacilities"]),
    async loadMore($state) {
      const { latitude, longitude } = this.savedLocation;
      if (!(latitude && latitude) || this.isLoading || this.noMoreData) {
        $state.complete();
        return;
      }

      this.isLoading = true;

      try {
        const data = await this.searchProperties({
          latitude,
          longitude,
          radius: 20,
          page: this.currentPage,
          perPage: this.perPage,
          facilities: this.selectedFacilities,
        });

        if (!data?.content?.length) {
          this.noMoreData = true;
          $state.complete();
        } else {
          this.propertiesList.push(...this.parseProperties(data));
          this.currentPage++;
          $state.loaded();
        }
      } catch (error) {
        console.error("Error fetching items:", error);
        $state.error();
      }

      this.isLoading = false;
    },
    parseProperties(properties) {
      if (!properties?.content) return [];
      properties = JSON.parse(JSON.stringify(properties.content));
      return properties.map(({ content: property }) => {
        property.ratingCount =
          property.googleReviews?.googleNumberOfReviews || 0;
        property.ratingValue = property.googleReviews?.googleRating || 0;

        if (property.media) {
          property.media
            .map((m) => {
              m.order = m.sortOrderMain || Number.MAX_VALUE;
              return m;
            })
            .sort((a, b) => a.order - b.order)
            .slice(0, 4);
        }
        return property;
      });
    },
    ratingValue(value) {
      return parseFloat(value).toFixed(1);
    },
    async findPlaces() {
      const { latitude, longitude, destination } = this.location;
      this.savedLocation = { latitude, longitude, destination };
      if (latitude && longitude) {
        const { latitude: lat, longitude: long } = this.$route.query;
        if (latitude != lat || longitude != long) {
          this.$router.replace({
            ...this.$router.currentRoute,
            query: {
              latitude,
              longitude,
              destination,
            },
          });
        }
        if (destination) {
          this.destination = destination;
        }
        this.resetPropertyList();
      }
    },
    resetPropertyList() {
      this.currentPage = 1;
      this.propertiesList = [];
      this.infiniteId += 1;
    },
    applyFilters() {
      setTimeout(() => this.resetPropertyList(), 100);
    },
    propertyUrl(id) {
      let { checkIn, checkOut } = this.date;
      if (checkIn && checkOut) {
        checkIn = moment(checkIn, "DD MMM YYYY").format("YYYY-MM-DD");
        checkOut = moment(checkOut, "DD MMM YYYY").format("YYYY-MM-DD");
        return `/listing/${id}?checkIn=${checkIn}&checkOut=${checkOut}&findAvailability=true`;
      } else {
        return `/listing/${id}?findAvailability=true`;
      }
    },
    openProperty(id) {
      this.$router.push(this.propertyUrl(id));
    },
    getRatingTranslationKey(score) {
      if (score >= 9.5 && score <= 10) {
        return "rating.exceptional";
      } else if (score >= 9 && score < 9.5) {
        return "rating.wonderful";
      } else if (score >= 8 && score < 9) {
        return "rating.veryGood";
      } else if (score >= 7 && score < 8) {
        return "rating.good";
      } else if (score >= 6 && score < 7) {
        return "rating.pleasant";
      } else if (score >= 5 && score < 6) {
        return "rating.reviewScore";
      } else if (score >= 4 && score < 5) {
        return "rating.passable";
      } else if (score >= 3 && score < 4) {
        return "rating.poor";
      } else if (score >= 2 && score < 3) {
        return "rating.veryPoor";
      } else if (score >= 1 && score < 2) {
        return "rating.disappointing";
      } else {
        return "rating.noRating";
      }
    },
    getTranslatedRating(score) {
      const key = this.getRatingTranslationKey(score);
      return this.$t(key);
    },
    propertyDescription(descriptions) {
      descriptions = Object.values(descriptions);
      if (!descriptions.length) return "";
      let description = descriptions.find(
        (d) => d.descriptionType.code === "hotel-short"
      );
      if (!description) {
        description = descriptions.find(
          (d) => d.descriptionType.code === "hotel-long"
        );
      }
      if (!description) {
        description = descriptions[0];
      }
      const el = document.createElement("div");
      el.innerHTML = description.text;
      const text = el.textContent;
      return text.length > 250 ? text.slice(0, 250) + "..." : text;
    },
    getDistanceInKm(lat, long) {
      const { latitude, longitude } = this.savedLocation;
      const toRad = (angle) => (angle * Math.PI) / 180;

      const dLat = toRad(latitude - lat);
      const dLon = toRad(longitude - long);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat)) *
          Math.cos(toRad(latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const distance = 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return Number(distance.toFixed(1)).toString() + "km";
    },
  },
};
</script>

<style>
@import "../assets/css/jquery-ui.css";
@import "../assets/css/calendar.css";
</style>

<style scoped>
.typesense-search-widget {
  width: 400px;
}
@media screen and (max-width: 767px) {
  .typesense-search-widget {
    top: 140px !important;
    width: 90% !important;
  }
}
</style>
